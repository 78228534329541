import * as React from 'react';

export interface ArrowProps extends React.SVGProps<SVGSVGElement> {
    /**
     * The foreground color for the arrow
     */
    fg: string;
    /**
     * The background color for the arrow
     */
    bg: string;
}

function ArrowRight({fg, bg, ...props}: ArrowProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 69.57 69.57"
            {...props}
        >
            <g id="prefix__Layer_2" data-name="Layer 2">
                <g id="prefix__Layer_1-2" data-name="Layer 1">
                    <path
                        d="M69.57 34.78A34.79 34.79 0 1134.78 0a34.8 34.8 0 0134.79 34.78"
                        fill={bg}
                    />
                    <path
                        fill={fg}
                        d="M22.92 51.65a2.5 2.5 0 01-1.2-4.65l26.43-14.42A2.5 2.5 0 1150.54 37L24.11 51.35a2.45 2.45 0 01-1.19.3z"
                    />
                    <path
                        fill={fg}
                        d="M49.34 37.27a2.45 2.45 0 01-1.19-.3L21.72 22.59a2.5 2.5 0 012.39-4.4l26.43 14.39a2.5 2.5 0 01-1.2 4.69z"
                    />
                </g>
            </g>
        </svg>
    );
}

export default ArrowRight;

/** @jsx jsx */
import {jsx} from '@emotion/react';
import * as React from 'react';
import {Link} from 'gatsby';
import ArrowRight from '@app/icons/arrow-right';

export interface LinkItemProps {
    /**
     * The url this item will link to
     */
    link: string;
    /**
     * The foreground colour of the button
     */
    btnFg: string;
    /**
     * The background colour of the button
     */
    btnBg: string;
    /**
     * An on click function callback. This will override the link and a page
     * navigation will not be preformed if this function is passed into the
     * component
     */
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const LinkItem: React.FC<React.PropsWithChildren<LinkItemProps>> = (
    props,
) => (
    <Link
        to={props.link}
        onClick={props.onClick}
        css={{
            textDecoration: 'none',
            display: 'flex',
            width: '100%',
            alignItems: 'center',
        }}
    >
        <h4 css={{color: 'white', width: '75%'}}>{props.children}</h4>
        <div css={{flexGrow: 1, textAlign: 'right'}}>
            <ArrowRight
                fg={props.btnFg}
                bg={props.btnBg}
                width="48px"
                height="48px"
            />
        </div>
    </Link>
);

export default LinkItem;

import DesktopWrapper from '@app/components/desktop-wrapper';
import LinkItem from '@app/components/into-link-item';
import Layout from '@app/components/layout';
import SEO from '@app/components/seo';
import Documents from '@app/icons/documents';
import theme from '@app/theme';
import React from 'react';

const IndexPage = () => (
    <Layout backgroundColor={theme.purple}>
        <SEO title="Toolkit" lang="en" />
        <DesktopWrapper>
            <div css={{padding: '60px 60px 0 60px', textAlign: 'center'}}>
                <Documents width="85px" height="85px" />
                <h1
                    css={{
                        color: theme.white,
                    }}
                >
                    Please choose a language.
                </h1>
            </div>
            <div css={{padding: '0 60px'}}>
                <LinkItem link="/en" btnFg={theme.white} btnBg={theme.red}>
                    English
                </LinkItem>
                <div css={{borderBottom: '2px solid white'}} />
                {/* <LinkItem link="/au" btnFg={theme.white} btnBg={theme.red}>
                    Australian English
                </LinkItem>
                <div css={{borderBottom: '2px solid white'}} /> */}
                {/* <LinkItem link="/ca-en" btnFg={theme.white} btnBg={theme.red}>
                    Canadian English
                </LinkItem>
                <div css={{borderBottom: '2px solid white'}} />
                <LinkItem link="/au" btnFg={theme.white} btnBg={theme.red}>
                    Canadian French
                </LinkItem>
                <div css={{borderBottom: '2px solid white'}} /> */}
                {/* <LinkItem link="/pl" btnFg={theme.white} btnBg={theme.red}>
                    Polish
                </LinkItem>
                <div css={{borderBottom: '2px solid white'}} /> */}
                <LinkItem link="/nl" btnFg={theme.white} btnBg={theme.red}>
                    Dutch
                </LinkItem>
            </div>
        </DesktopWrapper>
    </Layout>
);

export default IndexPage;
